import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
// CMD
const RongIMLib = require('@rongcloud/imlib-next')
const APPKEY = "25wehl3u24gkw";


let url = window.location.href;
// 获取参数部分
let params = url.split('?')[1];
// 将参数部分转换为对象
let paramsObj = {};
if (params) {
    let paramsArr = params.split('&');
    for (let i = 0; i < paramsArr.length; i++) {
    let param = paramsArr[i].split('=');
    paramsObj[param[0]] = param[1];
    }
}

const TOKEN =  paramsObj['token']; 
window.APPKEY = APPKEY;
window.TOKEN = TOKEN;
// ES
// import * as RongIMLib from '@rongcloud/imlib-next'



// 1 初始化
// RongIMLib.init({ appkey: APPKEY });


Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  async beforeCreate() {
    // 初始化imkit
    // let libOption = {appkey: APPKEY,logOutputLevel: 4}

    // RongIMLib.init(libOption);
    // imkit.init({
    //   appkey: APPKEY,
    //   service: custom_service,
    //   libOption: libOption,
    //   customIntercept: custom_conversation,
    //   customMessage: custom_message,
    //   customIntercept: custom_conversation,
    //   customDisplayMessage: customDisplayMessage
    // });

    // const PersonMessage = imkit.registerMessageType('kit:person', true, true, [], false)
    // const GroupMessage = imkit.registerMessageType('kit:GrpNtf', true, true, [], false)
    // window.PersonMessage = PersonMessage
    // window.GroupMessage = GroupMessage
  }
}).$mount('#app')

import axios from 'axios'
import { MessageBox, Message, Notification } from 'element-ui'
import store from '@/store'
import { getToken } from '@/utils/auth'
// 是否显示重新登录
let isRelogin = { show: false }
// create an axios instance
const service = axios.create({

  baseURL: 'https://app.shiwei.love/app', // url = base url + request url
  //  baseURL: 'https://lvshi.hn-enjoy.com', // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 20000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    // config.headers['login-type'] = 'pc'
    // const isToken = (config.headers || {}).isToken === false
    // if (getToken() && !isToken) {
    //   config.headers['user-token'] = getToken()
    // }
    // config.headers['Authorization'] = "Bearer FKxJ7ythZqPPnuKiTUrDevjACNATH9RQq2kPVwMoYAseNG2gXYNkaUtniKdFDRPnXSIDqxS6pDV4kApJW7QN8uSr63mxMlAL42Nh1LPno4kLc7J1NCRAnHNT4dXDCqLB"
    
    config.headers['Content-Type'] = 'multipart/form-data'
    // if (store.getters.token) {
    //   config.headers['user-token'] = getToken()
    // }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data

    // if the custom code is not 20000, it is judged as an error.
    if (res.code != 1) {
      // if (res.code == -201) {
      //   Message({
      //     message: res.msg || 'Error',
      //     type: 'error',
      //     duration: 5 * 1000
      //   })
      //   store.dispatch('user/resetToken').then(() => {
      //     location.reload()
      //   })
      // }
      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;

      // if (res.code == -202 || res.code === 50012 || res.code === 50014) {
      //   // to re-login
      //   MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
      //     confirmButtonText: 'Re-Login',
      //     cancelButtonText: 'Cancel',
      //     type: 'warning'
      //   }).then(() => {
      //     store.dispatch('user/resetToken').then(() => {
      //       location.reload()
      //     })
      //   })
      // }
      if (res.code == -201||res.code == -202||res.code == -203) {
        if (!isRelogin.show) {
          isRelogin.show = true
          MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
            confirmButtonText: '重新登录',
            cancelButtonText: '取消',
            type: 'warning'
          }
          ).then(() => {
            isRelogin.show = false
            store.dispatch('user/logout').then(() => {
              location.href = '/login'
            })
          }).catch(() => {
            isRelogin.show = false
          })
        }
        return Promise.reject('无效的会话，或者会话已过期，请重新登录。')
      }
      // todo
      // Message.error(res.msg)
      // Message({
      //   message:res.message,
      //   type: 'error',
      //   duration: 5 * 1000
      // })
      return res
    } else {
      return res
    }
  },
  error => {
    console.log('err' + error) // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service

import request from '@/utils/request'
const api = {

  // 公共
  queryAutograph:'/api/v2/token/oss',// 直传oss 临时token
  queryAutographSign:'/api/v2/token/sign',// 直传oss  签名
  
  // 登录

}


export function queryAutograph(data) { // 直传oss 临时token
  return request({
    url: api.queryAutograph,
    method: 'post',
    data
  })
}
export function queryAutographSign(data) { // 直传oss  签名
  return request({
    url: api.queryAutographSign,
    method: 'post',
    data
  })
}

// export function getWithdrawAccount (data) {
//   return request({
//     url: api.getWithdrawAccount,
//     method: 'get',
//     params: data
//   })
// }
// export function postBindWithdrawAccount (data) {
//   return request({
//     url: api.postBindWithdrawAccount,
//     method: 'post',
//     data
//   })
// }


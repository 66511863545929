<template>
  <div id="app">
   
 
        <div class="yinshipin-container">
          
          <div class="yinshipin-video-box">
              <div id="videoView" class="yinshipin-video-box-item">

              </div>
          </div>
          <div class="yinshipin-box">
              <div class="yinshipin-head">{{yinshipinType|filterCall}}</div>
              <!-- {{ yinshipinType }} -->
              <div class="yinshipin-content" >
                <img :src="pic" alt="" v-if="yinshiType ==1" class="yinshipin-content-head">
                <div class="yinshipin-content-name" v-if="yinshiType ==1">{{ name }}</div>
              </div>
              <div class="yinshipin-bottom">
                <div class="yinshipin-bottom-item " @click="microphone">
                  <img src="./assets/callmac.png" alt="" class="yinshipin-bottom-img " :class="isMicrophone?'':'angled'"/>
                  <div>
                    麦克风
                  </div>
                </div>
               
                <!-- <div class="yinshipin-bottom-item" @click="enableSpeaker">
                  <img src="./assets/calllb.png" alt="" class="yinshipin-bottom-img" :class="isEnableSpeaker?'':'angled'">
                  <div>
                    扬声器
                  </div>
                </div> -->
                <div class="yinshipin-bottom-item" @click="closeCameraCur" v-if="yinshiType ==2">
                  <img src="./assets/calllbcam.png" alt="" class="yinshipin-bottom-img" :class="curCamera?'':'angled'">
                  <div>
                    摄像头
                  </div>
                </div>

                <div class="yinshipin-bottom-item" v-if="yinshipinType == 11 || yinshipinType == 12" @click="hungup">
                  <img src="./assets/callred.png" alt="" class="yinshipin-bottom-img">
                  <div>
                    取消
                  </div>
                </div>

                

                <div class="yinshipin-bottom-item" @click="hungup" v-if="yinshipinType == 21 || yinshipinType == 22">
                  <img src="./assets/callred.png" alt="" class="yinshipin-bottom-img">
                  <div>
                    挂断
                  </div>
                </div>

                <div class="yinshipin-bottom-item" @click="accept" v-if="yinshipinType == 21 ">
                  <img src="./assets/callgreen.png" alt="" class="yinshipin-bottom-img">
                  <div>
                    接听
                  </div>
                </div>

              </div>
          </div>
        </div>
          


       
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import ConversationList from './components/conversation-list.vue'
import MessageList from './components/message-list.vue'
import MessageDeitor from './components/message-deitor.vue'

const RongIMLib = require('@rongcloud/imlib-next')
// import * as RongIMLib from "@rongcloud/imlib-next";
  // 导入 RTCLib、CallLib
  
import { installer as rtcInstaller, RCRTCClient, RCTrack, RCFrameRate, RCResolution } from "@rongcloud/plugin-rtc";
import { installer as callInstaller, RCCallClient, RCCallSession, RCCallErrorCode, ISessionListener, IEndSummary, ISenderInfo, IMuteUser, IInvitedUsers, RCCallLanguage, RCCallEndReason, RCCallMediaType, IOfflineRecord, RCCallSessionState } from "@rongcloud/plugin-call";

import web from './utils/hybrid_html_uni.webview.1.5.5'
const webview = web.webview
export default {
  name: 'App',
  components: {
    // HelloWorld
    ConversationList,
    MessageList,
    MessageDeitor
  },
  data() {
    return {
      msgArry:[],
      selectId:'', //用户id
      yinshiType:'',

      name:'', //用户name
      pic:'', //用户头像
      user:'', // 我的id
      rtcClient:'',
      caller:'',


      //
      sessionObj:'',
      dialogYinShiPin:false,
      callType: false, //音视频占用状态
      yinshipinType: 0, //音视频状态  0:没有使用   11：主叫拨号  12：主叫已接通  13：主叫挂断   21：被叫振铃中  22：被叫已接通  23:被叫已挂断


      isMicrophone:true, //麦克
      isEnableSpeaker:true, //扬声器
      curCamera:true, //摄像头

    };
  },
  filters: {
    filterCall(val){
      let text="准备中";
      switch (val) {
        case 11:
          text = "拨号中..."
        break;
        case 12:
          text = "通话中"
        break;
        case 13:
          text = "已挂断"
        break;
        case 21:
          text = "振铃中..."
        break;
        case 22:
          text = "通话中"
        break;
        case 23:
          text = "已挂断"
        break;
        default:
          // 默认代码块
          break;
      }
      return  text
    }
  },
  // filterCall
  async mounted() {
    
    //参数中  1 登录im token  2.音频1、视频2 yinshiType   3.通话id selectId  4.是否主动拨打 type 主动1 被动2   5.name  6.pic
    const APPKEY = "25wehl3u24gkw";
    RongIMLib.init({ appkey: APPKEY });
    let that = this;
    let url = window.location.href;
    // let url = `https://audio.shiwei.love/?token=nqnGcO4Za4r+5+VKJEUnelkHvPd8CVk3YwHKNHiheL0=@qca3.cn.rongnav.com;qca3.cn.rongcfg.com&yinshiType=2&selectId=100094kf&type=1&name=49999&pic=https://cdn.shiwei.love/config/2024/08/03/17226489573331P10dh1s0CHGLl4.PNG`;
  
        // 获取参数部分
      let params = url.split('?')[1];
        // 将参数部分转换为对象
        let paramsObj = {};
      if (params) {
        let paramsArr = params.split('&');
        for (let i = 0; i < paramsArr.length; i++) {
        let param = paramsArr[i].split('=');
        paramsObj[param[0]] = param[1];
        }
      }
    let type = paramsObj['type']
    this.selectId = paramsObj['selectId']
    this.yinshiType = paramsObj['yinshiType']
    let token =   params.split('&')[0].slice(6)
  //   console.log(token)
  //  return
    this.name = decodeURIComponent(paramsObj['name']) 
    this.pic = paramsObj['pic']
    console.log('主叫被叫',type) 
    
    // huoq
    // localStorage.getItem("lang") && localStorage.removeItem("lang");
    //
    // 3 登录IM  
   // let token = type? "nqnGcO4Za4ofkY6ec7zjOxAlGV3EC9DbwhQ3CS6xXQ8=@qca3.cn.rongnav.com;qca3.cn.rongcfg.com":"nqnGcO4Za4r+5+VKJEUnevB3BmNx3snUWc+xXJC1nTE=@qca3.cn.rongnav.com;qca3.cn.rongcfg.com"
  

    that.dialogYinShiPin = false;

   
    this.messageLook(type,token)
    // RTC 客户端初始化
    const rtcClient = RongIMLib.installPlugin(rtcInstaller, { /* 配置项 */ })
    that.caller = RongIMLib.installPlugin(callInstaller,{
    // rtcClient 实例 （必填）
    rtcClient,
    /**
     * 被动收到邀请 （收到一个远端发起的新会话）, 会产生一个新的 session 对象 （必填）
     */
     onSession: (session) => {
      console.log(`收到的通话邀请`);
       
      
        /**
         * **收到新的 session 后需要立即注册事件监听**
         */
        that.registerCallSessionEvent(session);

        that.callGet(session);
       
      },
      onSessionClose: (session, summary) => {
        console.log('通话已结束1',summary,session)
        that.dialogYinShiPin = false;
        that.yinshipinType = 0;
          // RCToast('通话已结束');
          // RCCallView.end();
          // removeVideoEl();
        },
         /**
         * 接收 IM 离线期间收到的呼叫记录（按需监听）
         */
        onOfflineRecord(record){
          console.log('呼叫记录',record)
        },

    })
    console.log('初始化RTC', rtcClient)
    console.log('初始化caller',that.caller)

    // 用户注册
    console.info('链接结果打印：',token);
    RongIMLib.connect(token).then(res => {
                    if (res.code === 0) {
                      console.info('链接结果打印：', res.data);


                      if(res.data){
                        that.user = res.data.userId;
                      }
                    }
                  });
              
                if(type==1){ // 主动拨打--进行拨打
                  let type = this.yinshiType == 1?'yuyin':'shipin'

                    setTimeout(()=>{
                      that.sendMssage({type:type})
                    },1200)
                
                }else{

                }
    

    },

    destroyed(){
      this.hungup()
    },
    beforeDestroy() {
      this.hungup()
    },
    methods: {

      backGo(){
        webview.navigateBack({
              delta: 1
          });
      },
      
      async sendMssage(msg){
        console.log('发送消息内容',msg)
        let that = this;
        if(msg.type == 'yuyin'){
            console.log('发送语音',that)
            // console.log(that.caller)
            const { code, session } = await that.caller.call({
              targetId: that.selectId, //用户id
              mediaType: RCCallMediaType.AUDIO, // 音频呼叫
              listener: {

                /**
                   * 当远端用户已开始响铃，表示对方已收到呼叫请求 （必填）
                   * @param sender 已响铃的用户
                   * @param session 当前的 session 对象
                   */
                  onRinging: (sender, session) => {
                      const { userId } = sender;
                      console.log('当远端用户已开始响铃，表示对方已收到呼叫请求',sender,session)
                      that.yinshipinType = 11;
                      that.callType = true;
                      that.dialogYinShiPin = true;
                      that.sessionObj = session;
                  },

                  /**
                   * 当远端用户同意接听 （必填）
                   * @param sender 远端用户
                   * @param session 当前的 session 对象
                   */
                  onAccept: (sender, session) => {
                      const { userId } = sender;
                      console.log('当远端用户同意接听',sender,session)
                      that.yinshipinType = 12;
                  },

                  /**
                   * 当有远端用户挂断 （必填）
                   * @param sender 远端用户
                   * @param reason 挂断的原因
                   * @param session 当前的 session 对象
                   */
                  onHungup: (sender, reason, session) => {
                      const { userId } = sender;
                      console.log('当有远端用户挂断',sender,reason,session)
                      that.yinshipinType = 0;
                      that.callType = false;
                      that.dialogYinShiPin = false; 
                      
                      document.getElementById("videoView").innerHTML = '';
                      that.backGo()
                  },

                  /**
                   * 本端资源或远端资源已获取， （必填）
                   * @param track 本端资源或远端资源, track不可设置成 Vue 组件的响应式数据
                   * @param session 当前的 session 对象
                   */
                  onTrackReady: (track, session) => {

                      // track.isLocalTrack() 是否为本地资源
                      // track.isAudioTrack() 是否为音频
                      // track.isVideoTrack() 是否为视频
                      // track.getUserId()    产生该 track 的用户id

                      console.log('本端资源或远端资源已获取',track,session)
                        // 播放音频。如果为远端音频，建议直接播放。如为本端音频，建议不播放，以减少回音。
                      if (track.isAudioTrack() && !track.isLocalTrack()) {
                          track.play();
                      }

                  },
                   /**
                       * 通话类型改变时触发，例如： 从视频变成音频通话 （必填）
                       * @param sender 发送者
                       * @param mediaType 1为音频通话   2为视频通话
                       * @param session 当前的 session 对象
                       */
                      onMediaModify: (sender, mediaType, session) => {
                        
                        console.log('当有远端用户挂断',sender,mediaType,session)
                      },
                      /**
                       * 对方静音后触发 （必填）
                       * @param muteUser 已静音的用户
                       * @param session  当前的 session 对象
                       */
                      onAudioMuteChange: (muteUser, session) => {
                        
                        console.log('对方静音后触发',muteUser,session)
                      },

                      /**
                       * 对方禁用视频后触发 （必填）
                       * @param muteUser 已禁用视频的用户
                       * @param session  当前的 session 对象
                       */
                      onVideoMuteChange: (muteUser, session) => {
                        
                        console.log('对方禁用视频后触发',muteUser,session)
                      },
                 
               
                 

                 
              }
            })

          if (code === RCCallErrorCode.SUCCESS) {
              // do something

              console.log('通话成功')
          }
        }else if(msg.type == 'shipin'){
            console.log('发送视频',that)
            console.log(that.caller)
            const { code, session } = await that.caller.call({
              targetId: that.selectId, //用户id
              mediaType: RCCallMediaType.AUDIO_VIDEO, // 视频呼叫
              listener: {

                /**
                   * 当远端用户已开始响铃，表示对方已收到呼叫请求 （必填）
                   * @param sender 已响铃的用户
                   * @param session 当前的 session 对象
                   */
                  onRinging: (sender, session) => {
                      const { userId } = sender;
                      console.log('当远端用户已开始响铃，表示对方已收到呼叫请求',sender,session)
                      that.yinshipinType = 11;
                      that.callType = true;
                      that.dialogYinShiPin = true;
                      that.sessionObj = session;
                  },

                  /**
                   * 当远端用户同意接听 （必填）
                   * @param sender 远端用户
                   * @param session 当前的 session 对象
                   */
                  onAccept: (sender, session) => {
                      const { userId } = sender;
                      console.log('当远端用户同意接听',sender,session)
                      that.yinshipinType = 12;
                  },

                  /**
                   * 当有远端用户挂断 （必填）
                   * @param sender 远端用户
                   * @param reason 挂断的原因
                   * @param session 当前的 session 对象
                   */
                  onHungup: (sender, reason, session) => {
                      const { userId } = sender;
                      console.log('当有远端用户挂断',sender,reason,session)
                      that.yinshipinType = 0;
                      that.callType = false;
                      that.dialogYinShiPin = false; 

                      
                      document.getElementById("videoView").innerHTML = '';
                      that.backGo()
                  },

                  /**
                   * 本端资源或远端资源已获取， （必填）
                   * @param track 本端资源或远端资源, track不可设置成 Vue 组件的响应式数据
                   * @param session 当前的 session 对象
                   */
                  onTrackReady: (track, session) => {

                      // track.isLocalTrack() 是否为本地资源
                      // track.isAudioTrack() 是否为音频
                      // track.isVideoTrack() 是否为视频
                      // track.getUserId()    产生该 track 的用户id

                      console.log('本端资源或远端资源已获取',track,session)
                        // 播放音频。如果为远端音频，建议直接播放。如为本端音频，建议不播放，以减少回音。
                      if (track.isAudioTrack() && !track.isLocalTrack()) {
                          track.play();
                      }

                      if (track.isVideoTrack() && !track.isLocalTrack()) {
                        
                        console.log('视频播放2')
                          // const video = document.getElementById("videoView") as HTMLVideoElement;
                            let container = document.getElementById("videoView");
                            let uid = track.getUserId();
                            console.log('视频播放3',uid)
                            let node = document.createElement('div');
                            
                            console.log('视频播放4',node)
                            node.setAttribute('id', `video-${uid}`);
                            let videoTpl = `<video style="width: 100%;height: 100%;" id="${uid}"></video>`;

                              
                            console.log('视频播放4',videoTpl)

                            node.innerHTML = videoTpl;
                            node.classList = 'video-item';
                            
                            console.log('视频播放5',node)
                            console.log('视频播放6',container)
                            container.appendChild(node);
                            let videoEl = document.getElementById(track.getUserId());

                            
                            console.log(videoEl)
                            track.play(videoEl)


                          // track.play(video);
                      }
                      if (track.isVideoTrack() && track.isLocalTrack()) {
                        
                          console.log('视频播放2')
                          // const video = document.getElementById("videoView") as HTMLVideoElement;
                            let container = document.getElementById("videoView");
                            let uid = track.getUserId();

                            let useCotent = document.getElementById(`video-${uid}`);
                            if(useCotent){
                              container.removeChild(useCotent)
                            }

                            console.log('视频播放3',uid)
                            let node = document.createElement('div');
                            
                            console.log('视频播放4',node)
                            node.setAttribute('id', `video-${uid}`);
                            let videoTpl = `<video  style=" width: 150px;" id="${uid}"></video>`;

                              
                            console.log('视频播放4',videoTpl)

                            node.innerHTML = videoTpl;
                            node.classList = 'video-item-my';
                            
                            console.log('视频播放5',node)
                            console.log('视频播放6',container)
                            container.appendChild(node);
                            let videoEl = document.getElementById(track.getUserId());

                            
                            console.log(videoEl)
                            track.play(videoEl)


                          // track.play(video);
                      }
                  },
                   /**
                       * 通话类型改变时触发，例如： 从视频变成音频通话 （必填）
                       * @param sender 发送者
                       * @param mediaType 1为音频通话   2为视频通话
                       * @param session 当前的 session 对象
                       */
                      onMediaModify: (sender, mediaType, session) => {
                        
                        console.log('当有远端用户挂断',sender,mediaType,session)
                      },
                      /**
                       * 对方静音后触发 （必填）
                       * @param muteUser 已静音的用户
                       * @param session  当前的 session 对象
                       */
                      onAudioMuteChange: (muteUser, session) => {
                        
                        console.log('对方静音后触发',muteUser,session)
                      },

                      /**
                       * 对方禁用视频后触发 （必填）
                       * @param muteUser 已禁用视频的用户
                       * @param session  当前的 session 对象
                       */
                      onVideoMuteChange: (muteUser, session) => {
                        
                        console.log('对方禁用视频后触发',muteUser,session)
                      },
                 
               
                 

                 
              }
            })

          if (code === RCCallErrorCode.SUCCESS) {
              // do something

              console.log('通话成功')
          }
        }
       
       
      },

      // 接听
      accept(){
        let that = this;
          that.sessionObj.accept().then(({ code }) => {
            if (code === RCCallErrorCode.SUCCESS) {
              console.log('接听成功')
              that.yinshipinType = 22;
            } else {
              console.log(`接听失败，错误原因：${code}`)
            }
        })
      },
      // 挂断
      hungup () {
        let that = this;
        that.sessionObj.hungup().then(({ code }) => {
          if (code === RCCallErrorCode.SUCCESS) {
            // that.dialogYinShiPin = false
            console.log('挂断成功')
            that.yinshipinType = 0;
            that.callType = false;
            that.dialogYinShiPin = false;
            that.backGo()
          } else {
            console.log(`接听失败，错误原因：${code}`)
          }
        })
      },

      callGet(session){
            let that = this;
            if(that.callType){
            console.log('通话占用，进行挂断')
            session.hungup()
            that.backGo()
            return
          }
          that.sessionObj = session;
          that.yinshipinType = 21;
          that.callType = true;
          this.dialogYinShiPin = true;
         
          console.log(`收到的通话邀请弹框成功`,this.dialogYinShiPin);

          
      },
      registerCallSessionEvent(session){
        let that = this;
        session.registerSessionListener({
          onRinging: (sender) => {
            RCToast(`收到 ${sender.userId} 振铃`);
          },
          /**
           * 当远端用户已开始响铃，表示对方已收到呼叫请求
           * @param sender 已响铃的用户
           * @param session 当前的 session 对象
           */
           onRinging: (sender) => {
              console.log('开始振铃',sender)
              const { userId } = sender;
          },


          /**
           * 当远端用户同意接听
           * @param sender 远端用户
           * @param session 当前的 session 对象
           */
           onAccept: (sender) => {
              console.log(`${sender.userId} 已接听`);
              console.log('当远端用户同意接听',sender)
              that.yinshipinType = 22;
            },
        
          /**
           * 当有远端用户挂断
           * @param sender 远端用户
           * @param reason 挂断的原因
           * @param session 当前的 session 对象
           */
         
           onHungup: (sender) => {
            console.log(`${sender.userId} 已挂断`);
              console.log('当有远端用户挂断',sender)
              that.yinshipinType = 0;
              that.callType = false;
              
              document.getElementById("videoView").innerHTML = '';
              that.backGo()
          },

          /**
           * 本端资源或远端资源已获取
           * @param track 本端资源或远端资源, track 不可设置成 Vue 组件的响应式数据
           * @param session 当前的 session 对象
           */
           onTrackReady: (track) => {
              // track.isLocalTrack() 是否为本地资源
              // track.isAudioTrack() 是否为音频
              // track.isVideoTrack() 是否为视频
              // track.getUserId()    产生该 track 的用户id

              // 播放音频。如果为远端音频，建议直接播放。如为本端音频，建议不播放，以减少回音。
            if (track.isAudioTrack() && !track.isLocalTrack()) {
                  track.play();
              }

              // 视频在对应的容器里播放
              if (track.isVideoTrack() && !track.isLocalTrack()) {
                        
                        console.log('视频播放2')
                          // const video = document.getElementById("videoView") as HTMLVideoElement;
                            let container = document.getElementById("videoView");
                            let uid = track.getUserId();
                            console.log('视频播放3',uid)
                            let node = document.createElement('div');
                            
                            console.log('视频播放4',node)
                            node.setAttribute('id', `video-${uid}`);
                            let videoTpl = `<video style="width: 100%;height: 100%;" id="${uid}"></video>`;

                              
                            console.log('视频播放4',videoTpl)

                            node.innerHTML = videoTpl;
                            node.classList = 'video-item';
                            
                            console.log('视频播放5',node)
                            console.log('视频播放6',container)
                            container.appendChild(node);
                            let videoEl = document.getElementById(track.getUserId());

                            
                            console.log(videoEl)
                            track.play(videoEl)


                          // track.play(video);
                      }
                      if (track.isVideoTrack() && track.isLocalTrack()) {
                        
                        console.log('视频播放2')
                          // const video = document.getElementById("videoView") as HTMLVideoElement;
                            let container = document.getElementById("videoView");
                            let uid = track.getUserId();

                            let useCotent = document.getElementById(`video-${uid}`);
                            if(useCotent){
                              container.removeChild(useCotent)
                            }
                            console.log('视频播放3',uid)
                            let node = document.createElement('div');
                            
                            console.log('视频播放4',node)
                            node.setAttribute('id', `video-${uid}`);
                            let videoTpl = `<video  style=" width: 150px;" id="${uid}"></video>`;

                              
                            console.log('视频播放4',videoTpl)

                            node.innerHTML = videoTpl;
                            node.classList = 'video-item-my';
                            
                            console.log('视频播放5',node)
                            console.log('视频播放6',container)
                            container.appendChild(node);
                            let videoEl = document.getElementById(track.getUserId());

                            
                            console.log(videoEl)
                            track.play(videoEl)


                          // track.play(video);
                      }
          },
          onMemberModify: (sender) => {},
          onMediaModify: (sender) => {},
          onAudioMuteChange: (muteUser) => {},
          onVideoMuteChange: (muteUser) => {}


        });
      },

      messageLook(type,token){
        console.log('监听')
        let that = this;
        // 2 设置监听   3 登录IM  4.获取会话列表
          const Events = RongIMLib.Events
          RongIMLib.addEventListener(Events.CONNECTING, () => {
              console.log('正在链接服务器')
          })
          RongIMLib.addEventListener(Events.CONNECTED, () => {
              console.log('已经链接到服务器')
             
          })
          RongIMLib.addEventListener(Events.MESSAGES, (evt) => {
              console.log('消息监听',evt.messages)
             //that.$refs.msglist.upDataList(evt.messages[0]) 
          })



      },

      async microphone() {
				// this.isMicrophone = !this.isMicrophone;
        if(this.isMicrophone){
          const { code } = await this.sessionObj.disableAudioTrack()
          console.log(code,RCCallErrorCode)
          
          if (code === RCCallErrorCode.SUCCESS) {
                this.isMicrophone = !this.isMicrophone;
          }
        }else{
          const { code } = await this.sessionObj.enableAudioTrack()
          console.log(code)
          if (code === RCCallErrorCode.SUCCESS) {
                this.isMicrophone = !this.isMicrophone;
            }
        }
			},

      enableSpeaker() {
				this.isEnableSpeaker = !this.isEnableSpeaker;
				// this.caller.enableSpeaker(this.isEnableSpeaker);
			},
      async closeCameraCur() {
			
        if(this.curCamera){
          const { code } = await this.sessionObj.disableVideoTrack()
          console.log(code,RCCallErrorCode)
          
          if (code === RCCallErrorCode.SUCCESS) {
                // do something
                this.curCamera = !this.curCamera;
          }
        }else{
          const { code } = await this.sessionObj.enableVideoTrack()
          console.log(code)
          // this.curCamera = !this.curCamera;
          if (code === RCCallErrorCode.SUCCESS) {
                // do something
                this.curCamera = !this.curCamera;
            }
        }
				// let camera = this.caller.currentCamera();
				
			},
    }

// const RongIMLib = require('@rongcloud/imlib-next')
}
</script>

<style>
*{
  margin: 0;
  padding: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
  height: 100vh;
}
.el-container{
  height: 100%;
}
.talk-box{
  width: 100%;
  height: 100%;
  /* border: 1px solid #eee; */
}
/deep/ .el-main{
  padding: 0;
}
.padding0{
  padding: 0 !important;
}
 .yinshipin-dialog  .el-dialog{
  width: 375px !important;
  margin: 0 auto;
}
.yinshipin-dialog .el-dialog__header{
  padding: 0;
}
.yinshipin-dialog .el-dialog__body{
  padding: 0;
}
.yinshipin-container{
  background-color: #000;
  position: relative;
  width: 100%;
  height:  100vh;
}
.yinshipin-video-box{
  /* background-color: rgb(197, 126, 126); */
  width: 100%;
  height: 100%;
}
.yinshipin-box  {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
}
.yinshipin-head{
  height: 50px;
  line-height: 50px;
  color: #fff;
}
.yinshipin-content{
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.yinshipin-content-head{
  width: 108px;
  height: 108px;
}
.yinshipin-content-name{
 
  line-height: 67px;
  font-weight: bold;
  font-size: 24px;
  color: #FFFFFF;
}
.yinshipin-bottom{
  height: 110px;
  display: flex;
  justify-content: space-around;
}
.yinshipin-bottom-item{
  width: 60px;
  color: #fff;
}
.yinshipin-bottom-img{
  width: 60px;
  height: 60px;
}
.yinshipin-video-box-item{
  width: 100%;
  height: 100%;
}
.video-item{
  width: 100%;
  height: 100%;
}
.video-item-my{
  position: absolute;
  right: 10px;
  top: 40px;
  background-color: #eee;
}
.angled {
  /* background-color: #ac0; */
  border-radius: 50%;
  background-color: rgba(225, 225, 225, 0.8);
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(.25, rgba(0, 0, 0, 0.8)), color-stop(.25, transparent), color-stop(.5, transparent), color-stop(.5, rgba(0, 0, 0, 0.8)), color-stop(.75, rgba(0, 0, 0, 0.8)), color-stop(.75, transparent), to(transparent));
  background-image: -moz-linear-gradient(45deg, rgba(0, 0, 0, 0.8) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, 0.8) 50%, rgba(0, 0, 0, 0.8) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(0, 0, 0, 0.8) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, 0.8) 50%, rgba(0, 0, 0, 0.8) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.8) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, 0.8) 50%, rgba(0, 0, 0, 0.8) 75%, transparent 75%, transparent);
}

</style>



const state = {
 
  userInfo: {}

}

const mutations = {
  CHANGE_SETTING: (state, { key, value }) => {
    // eslint-disable-next-line no-prototype-builtins
    if (state.hasOwnProperty(key)) {
      state[key] = value
    }
  },
  initApp(state) {
    const _userInfo = JSON.parse(localStorage.getItem('userInfo'))
    if (_userInfo) {
      state.userInfo = _userInfo
      // global.userInfo = _userInfo;
      // global.token = _userInfo.user_token;
    }
  }
}

const actions = {
  changeSetting({ commit }, data) {
    commit('CHANGE_SETTING', data)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}


<template>
    <div class="conversation-list-box">
      
        <div class="conversation-list-item" 
            v-for="(item,index) in msgArry" 
            :key="index" 
            :class="{'active':item.targetId == selectId}"
            >
            <img src="./logo.png" alt="" class="conversation-list-head">
            <div class="conversation-list-msg">
                <div class="conversation-list-msg-title">{{item.name}}{{ item.targetId }}</div>
              
                <div class="conversation-list-msg-text">{{ item.msg }}</div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  
  // const RongIMLib = require('@rongcloud/imlib-next')
  export default {
    name: 'ConversationList',
    props: {
      msg: String,
      msgArry: Array,
      selectId: String
    },

    methods: {

      getList(){

      }
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }

  .conversation-list-box{
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    border-right: 1px solid #eee;
  }

  .conversation-list-item{
    display: flex;
    padding: 16px;
    background-color: #e5e4e4;
    border-bottom: 1px solid #eee;
  }
  .active{
    background-color: #ff953e;
  }
  .conversation-list-head{
    width: 50px;
    height: 50px;
  } 
  .conversation-list-msg{
    flex: 1;
    text-align: left;
    margin-left: 16px;
  }
  .conversation-list-msg-title{
    font-weight: bold;
    margin-bottom: 6px;
  }
  </style>
  
import { login, getInfo, getMsgPonit } from '@/api/api'
import { getToken, setToken, removeToken } from '@/utils/auth'
// import { resetRouter } from '@/router'
import { Message } from 'element-ui'

const getDefaultState = () => {
  return {
    token: getToken(),
    name: '',
    avatar: '',
    userInfo: {},
    msg: 0
  }
}

const state = getDefaultState()

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_USERID: (state, id) => {
    state.userid = id
  },
  SET_USERINFO: (state, info) => {
    state.userInfo = info
  },
  SET_MSG: (state, msg) => {
    state.msg = msg
  }
}

const actions = {
  // user login
  login({ commit }, userInfo) {
    const { username, password } = userInfo
    return new Promise((resolve, reject) => {
      login({ mobile: username.trim(), password: password, type: 1 }).then(response => {
        console.log(response)
        if (response.code != 1) {
          Message.error({
            message: response.msg
          })
          resolve()
        }
        const { data } = response
        commit('SET_TOKEN', data.userinfo.user_token)
        commit('SET_NAME', data.userinfo.user_token)
        commit('SET_AVATAR', data.userinfo.head_img)
        console.log(data.userinfo)
        commit('SET_USERINFO', data.userinfo)
        setToken(data.userinfo.user_token)
        resolve(data.is_login)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo(state.userInfo.id).then(response => {
        const { data } = response

        if (!data) {
          return reject('Verification failed, please Login again.')
        }
        commit('SET_USERINFO', data)

        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // user logout
  logout({ commit, state }) {
    return new Promise((resolve) => {
      console.log(state)
      removeToken() // must remove  token  first
      // resetRouter()
      commit('RESET_STATE')
      resolve()
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      removeToken() // must remove  token  first
      commit('RESET_STATE')
      resolve()
    })
  },

  // 消息提醒
  msgPoint({ commit }) {
    console.log('进入消息提醒')
    return new Promise((resolve, reject) => {
      getMsgPonit().then(response => {
        console.log(response)
        if (response.code != 1) {
          Message.error({
            message: response.msg
          })
          resolve()
        }
        const { data } = response
        commit('SET_MSG', data.count)
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

